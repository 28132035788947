@font-face {
  font-family: 'Fira Code';
  src: url('fira_code/woff2/FiraCode-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('dm_sans/DMSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
