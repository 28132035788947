@import "src/assets/fonts/fonts.scss";
@import "src/assets/styles/themes";
@import "src/assets/styles/mixins";
@import "src/assets/styles/carousel";
@import "src/assets/styles/functions";

body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: var(--bg-color);
    color: var(--primary-color);
    line-height: 1.3;
    font-family: var(--primary-font);
    font-size: 15px;
}

::-webkit-scrollbar {
    display: none;
}

section {
    background: transparent;
    min-height: 80vh;
    margin: 150px auto 200px;
    width: 95vw;
    max-width: 850px;
}

i {
    transition: var(--transition);
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
    }
}

ol, ul, li {
    list-style: none;
    padding: 0;
}

.highlight {
    text-decoration: none;
    display: inline-block;
    position: relative;
    color: var(--accent-color) !important;
    transition: var(--transition);
}

.bullet::marker {
    content: '> ';
    font-size: 20px;
    color: var(--accent-color);
}

.scroll-lock {
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.main-btn {
    color: var(--btn-text-color);
    border: var(--border);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: var(--transition) !important;
    font-family: var(--primary-font);
    cursor: var(--cursor-type);
    background-color: transparent;
    line-height: 1;
    text-decoration: none;
    padding: 20px 30px;

    &:hover {
        background-color: var(--bg-color-30);
        color: var(--btn-text-color) !important;
    }
}

.container {
    min-height: 100%;
    margin: auto;
    width: 90vw;
}

.section-title {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0 40px;
    width: 100%;
    font-size: xx-large;
    color: var(--primary-color);
    font-family: var(--primary-font);

    &:after {
        background-color: var(--border-color);
        content: '';
        display: block;
        height: 1px;
        width: 260px;
        position: relative;
        margin-left: 20px;
    }
}

@media(max-width: 1000px) {
    body {
        overflow-x: hidden;
    }
}
